import DashboardLayout from "@/pages/layouts/DashboardLayout";

let redirectionRoutes = {
  path: '/redirection-pages',
  component: DashboardLayout,
  redirect: '/redirection-pages/create',
  children: [
    {
      path: 'create/ar',
      name: 'Add Redirection Page',
      component: () => import(/* webpackChunkName: "redirection-pages" */  '@/pages/pages/RedirectionForm'),
      meta: {
        permissionsCodes: ['ar/redirection-pages/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Redirection Page',
      component: () => import(/* webpackChunkName: "redirection-pages" */  '@/pages/pages/RedirectionForm'),
      meta: {
        permissionsCodes: ['ar/redirection-pages/update', 'restricted_own/ar/redirection-pages/update'],
      }
    }
  ]
}

export default redirectionRoutes;
