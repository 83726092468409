import DashboardLayout from "@/pages/layouts/DashboardLayout";

let projectPrincipleRoutes = {
  path: '/project-principles',
  component: DashboardLayout,
  redirect: '/project-principles/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Principle List',
      component: () => import(/* webpackChunkName: "Principle" */  '@/pages/projects/ProjectPrincipleList'),
      meta: {
        permissionsCodes: ['ar/project-principles/index','restricted_own/ar/project-principles/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Principle',
      component: () => import(/* webpackChunkName: "Principle" */  '@/pages/projects/ProjectPrincipleForm'),
      meta: {
        permissionsCodes: ['ar/project-principles/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Principle',
      component: () => import(/* webpackChunkName: "Principle" */  '@/pages/projects/ProjectPrincipleForm'),
      meta: {
        permissionsCodes: ['ar/project-principles/update','restricted_own/ar/project-principles/update'],
      }
    }
  ]
}

export default projectPrincipleRoutes;
