import DashboardLayout from "@/pages/layouts/DashboardLayout";

let videoRoutes = {
  path: '/videos',
  component: DashboardLayout,
  redirect: '/videos/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Video List',
      component: () => import(/* webpackChunkName: "videos" */  '@/pages/videos/VideoList'),
      meta: {
        permissionsCodes: ['ar/videos/index','restricted_own/ar/videos/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Video',
      component: () => import(/* webpackChunkName: "videos" */  '@/pages/videos/VideoForm'),
      meta: {
        permissionsCodes: ['ar/videos/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Video',
      component: () => import(/* webpackChunkName: "videos" */  '@/pages/videos/VideoForm'),
      meta: {
        permissionsCodes: ['ar/videos/update','restricted_own/ar/videos/update'],
      }
    }
  ]
}

export default videoRoutes;
