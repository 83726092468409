import DashboardLayout from "@/pages/layouts/DashboardLayout";

let conferenceVideoRoutes = {
  path: '/conference-videos',
  component: DashboardLayout,
  redirect: '/conference-videos/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Video List',
      component: () => import(/* webpackChunkName: "video" */  '@/pages/conferences/conferencesVideos/ConferenceVideoList'),
      meta: {
        permissionsCodes: ['ar/conference-videos/index','restricted_own/ar/conference-videos/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Video',
      component: () => import(/* webpackChunkName: "video" */  '@/pages/conferences/conferencesVideos/ConferenceVideoForm'),
      meta: {
        permissionsCodes: ['ar/conference-videos/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Video',
      component: () => import(/* webpackChunkName: "video" */  '@/pages/conferences/conferencesVideos/ConferenceVideoForm'),
      meta: {
        permissionsCodes: ['ar/conference-videos/update','restricted_own/ar/conference-videos/update'],
      }
    }
  ]
}

export default conferenceVideoRoutes;
