import DashboardLayout from "@/pages/layouts/DashboardLayout";

let leadershipRoutes = {
  path: '/networks',
  component: DashboardLayout,
  redirect: '/networks/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Network List',
      component: () => import(/* webpackChunkName: "networks" */  '@/pages/networks/NetworkList'),
      meta: {
        permissionsCodes: ['ar/networks/index','restricted_own/ar/networks/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Network',
      component: () => import(/* webpackChunkName: "networks" */  '@/pages/networks/NetworkForm'),
      meta: {
        permissionsCodes: ['ar/networks/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Network',
      component: () => import(/* webpackChunkName: "networks" */  '@/pages/networks/NetworkForm'),
      meta: {
        permissionsCodes: ['ar/networks/update','restricted_own/ar/networks/update'],
      }
    }
  ]
}

export default leadershipRoutes;
