import DashboardLayout from "@/pages/layouts/DashboardLayout";

let conferenceGalleryRoutes = {
  path: '/conference-gallery',
  component: DashboardLayout,
  redirect: '/conference-gallery/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Gallery List',
      component: () => import(/* webpackChunkName: "gallery" */  '@/pages/conferences/conferencesGallery/ConferenceGalleryList'),
      meta: {
        permissionsCodes: ['ar/conference-gallery/index','restricted_own/ar/conference-gallery/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Gallery',
      component: () => import(/* webpackChunkName: "gallery" */  '@/pages/conferences/conferencesGallery/ConferenceGalleryForm'),
      meta: {
        permissionsCodes: ['ar/conference-gallery/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Gallery',
      component: () => import(/* webpackChunkName: "gallery" */  '@/pages/conferences/conferencesGallery/ConferenceGalleryForm'),
      meta: {
        permissionsCodes: ['ar/conference-gallery/update','restricted_own/ar/conference-gallery/update'],
      }
    }
  ]
}

export default conferenceGalleryRoutes;
