import DashboardLayout from "@/pages/layouts/DashboardLayout";

let conferenceRoutes = {
  path: '/conferences',
  component: DashboardLayout,
  redirect: '/conferences/create',
  children: [
    {
      path: 'create/:lang',
      name: 'Manage Conference',
      component: () => import(/* webpackChunkName: "conferences" */  '@/pages/conferences/ConferenceForm'),
      meta: {
        permissionsCodes: ['ar/conferences/create'],
      }
    },
  ]
}

export default conferenceRoutes;
