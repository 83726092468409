import DashboardLayout from "@/pages/layouts/DashboardLayout";

let electionInformationRoutes = {
  path: '/election-information',
  component: DashboardLayout,
  redirect: '/election-information/list',
  children: [
    {
      path: 'List/ar',
      name: 'Election Information List',
      component: () => import(/* webpackChunkName: "election-information" */  '@/pages/electionInformation/ElectionInformationList'),
      meta: {
        permissionsCodes: ['ar/election-information/index', 'restricted_own/ar/election-information/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Election Information',
      component: () => import(/* webpackChunkName: "election-information" */  '@/pages/electionInformation/ElectionInformationForm'),
      meta: {
        permissionsCodes: ['ar/election-information/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Election Information',
      component: () => import(/* webpackChunkName: "election-information" */  '@/pages/electionInformation/ElectionInformationForm'),
      meta: {
        permissionsCodes: ['ar/election-information/update', 'restricted_own/ar/election-information/update'],
      }
    }
  ]
}

export default electionInformationRoutes;
