import DashboardLayout from "@/pages/layouts/DashboardLayout";

let galleryRoutes = {
  path: '/gallery',
  component: DashboardLayout,
  redirect: '/gallery/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Gallery List',
      component: () => import(/* webpackChunkName: "gallery" */  '@/pages/gallery/GalleryList'),
      meta: {
        permissionsCodes: ['ar/gallery/index','restricted_own/ar/gallery/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Image',
      component: () => import(/* webpackChunkName: "gallery" */  '@/pages/gallery/GalleryForm'),
      meta: {
        permissionsCodes: ['ar/gallery/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Image',
      component: () => import(/* webpackChunkName: "gallery" */  '@/pages/gallery/GalleryForm'),
      meta: {
        permissionsCodes: ['ar/gallery/update','restricted_own/ar/gallery/update'],
      }
    }
  ]
}

export default galleryRoutes;
