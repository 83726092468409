import DashboardLayout from "@/pages/layouts/DashboardLayout";

let leadershipRoutes = {
  path: '/leaderships',
  component: DashboardLayout,
  redirect: '/leaderships/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Leadership List',
      component: () => import(/* webpackChunkName: "leaderships" */  '@/pages/leaderships/LeadershipList'),
      meta: {
        permissionsCodes: ['ar/leaderships/index','restricted_own/ar/leaderships/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Leadership',
      component: () => import(/* webpackChunkName: "leaderships" */  '@/pages/leaderships/LeadershipForm'),
      meta: {
        permissionsCodes: ['ar/leaderships/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Leadership',
      component: () => import(/* webpackChunkName: "leaderships" */  '@/pages/leaderships/LeadershipForm'),
      meta: {
        permissionsCodes: ['ar/leaderships/update','restricted_own/ar/leaderships/update'],
      }
    }
  ]
}

export default leadershipRoutes;
