import DashboardLayout from "@/pages/layouts/DashboardLayout";

let electionActivitiesRoutes = {
  path: '/election-activities',
  component: DashboardLayout,
  redirect: '/election-activities/list',
  children: [
    {
      path: 'List/ar',
      name: 'Election Activities List',
      component: () => import(/* webpackChunkName: "election-activities" */  '@/pages/electionActivities/ElectionActivityList'),
      meta: {
        permissionsCodes: ['ar/election-activities/index', 'restricted_own/ar/election-activities/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Election Activities',
      component: () => import(/* webpackChunkName: "election-activities" */  '@/pages/electionActivities/ElectionActivityForm'),
      meta: {
        permissionsCodes: ['ar/election-activities/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Election Activities',
      component: () => import(/* webpackChunkName: "election-activities" */  '@/pages/electionActivities/ElectionActivityForm'),
      meta: {
        permissionsCodes: ['ar/election-activities/update', 'restricted_own/ar/election-activities/update'],
      }
    }
  ]
}

export default electionActivitiesRoutes;
