import DashboardLayout from "@/pages/layouts/DashboardLayout";

let leadershipGroupsRoutes = {
  path: '/leadership-groups',
  component: DashboardLayout,
  redirect: '/leadership-groups/list',
  children: [
    {
      path: 'list/ar',
      name: 'Leadership Group List',
      component: () => import(/* webpackChunkName: "leaderships" */  '@/pages/leaderships/LeadershipsGroupList'),
      meta: {
        permissionsCodes: ['ar/leadership-groups/index', 'restricted_own/ar/leadership-groups/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Leadership Group',
      component: () => import(/* webpackChunkName: "leaderships" */  '@/pages/leaderships/LeadershipsGroupForm'),
      meta: {
        permissionsCodes: ['ar/leadership-groups/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Leadership Group',
      component: () => import(/* webpackChunkName: "leaderships" */  '@/pages/leaderships/LeadershipsGroupForm'),
      meta: {
        permissionsCodes: ['ar/leadership-groups/update', 'restricted_own/ar/leadership-groups/update'],
      }
    }
  ]
}

export default leadershipGroupsRoutes;
