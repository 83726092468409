import DashboardLayout from "@/pages/layouts/DashboardLayout";

let videoCategoryRoutes = {
  path: '/video-categories',
  component: DashboardLayout,
  redirect: '/video-categories/list',
  children: [
    {
      path: 'list/ar',
      name: 'Category List',
      component: () => import(/* webpackChunkName: "videos" */  '@/pages/videos/VideoCategoryList'),
      meta: {
        permissionsCodes: ['ar/video-categories/index', 'restricted_own/ar/video-categories/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Category',
      component: () => import(/* webpackChunkName: "videos" */  '@/pages/videos/VideoCategoryForm'),
      meta: {
        permissionsCodes: ['ar/video-categories/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Category',
      component: () => import(/* webpackChunkName: "videos" */  '@/pages/videos/VideoCategoryForm'),
      meta: {
        permissionsCodes: ['ar/video-categories/update', 'restricted_own/ar/video-categories/update'],
      }
    }
  ]
}

export default videoCategoryRoutes;
