import DashboardLayout from "@/pages/layouts/DashboardLayout";

let divisionRoutes = {
  path: '/divisions',
  component: DashboardLayout,
  redirect: '/divisions/list',
  children: [
    {
      path: 'list/ar',
      name: 'Division List',
      component: () => import(/* webpackChunkName: "divisions" */  '@/pages/divisions/DivisionList'),
      meta: {
        permissionsCodes: ['ar/divisions/index', 'restricted_own/ar/divisions/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Division',
      component: () => import(/* webpackChunkName: "divisions" */  '@/pages/divisions/DivisionForm'),
      meta: {
        permissionsCodes: ['ar/divisions/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Division',
      component: () => import(/* webpackChunkName: "divisions" */  '@/pages/divisions/DivisionForm'),
      meta: {
        permissionsCodes: ['ar/divisions/update', 'restricted_own/ar/divisions/update'],
      }
    }
  ]
}

export default divisionRoutes;
