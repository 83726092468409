import DashboardLayout from "@/pages/layouts/DashboardLayout";

let situationRoutes = {
  path: '/situations',
  component: DashboardLayout,
  redirect: '/situations/list',
  children: [
    {
      path: 'list/ar',
      name: 'Situations List',
      component: () => import(/* webpackChunkName: "situations" */  '@/pages/situations/SituationList'),
      meta: {
        permissionsCodes: ['ar/situations/index', 'restricted_own/ar/situations/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Situation',
      component: () => import(/* webpackChunkName: "situations" */  '@/pages/situations/SituationForm'),
      meta: {
        permissionsCodes: ['ar/situations/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Situation',
      component: () => import(/* webpackChunkName: "situations" */  '@/pages/situations/SituationForm'),
      meta: {
        permissionsCodes: ['ar/situations/update', 'restricted_own/ar/situations/update'],
      }
    }
  ]
}

export default situationRoutes;
