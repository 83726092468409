import DashboardLayout from "@/pages/layouts/DashboardLayout";

let candidatesRoutes = {
  path: '/candidates',
  component: DashboardLayout,
  redirect: '/candidates/list',
  children: [
    {
      path: 'list/ar',
      name: 'candidates List',
      component: () => import(/* webpackChunkName: "candidates" */  '@/pages/candidates/CandidateList'),
      meta: {
        permissionsCodes: ['ar/candidates/index', 'restricted_own/ar/candidates/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add candidates',
      component: () => import(/* webpackChunkName: "candidates" */  '@/pages/candidates/CandidateForm'),
      meta: {
        permissionsCodes: ['ar/candidates/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit candidates',
      component: () => import(/* webpackChunkName: "candidates" */  '@/pages/candidates/CandidateForm'),
      meta: {
        permissionsCodes: ['ar/candidates/update', 'restricted_own/ar/candidates/update'],
      }
    }
  ]
}

export default candidatesRoutes;
