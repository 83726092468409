import DashboardLayout from "@/pages/layouts/DashboardLayout";

let contentRoutes = {
  path: '/content-pages',
  component: DashboardLayout,
  redirect: '/content-pages/create',
  children: [
    {
      path: 'create/ar',
      name: 'Add Content Page',
      component: () => import(/* webpackChunkName: "content-pages" */  '@/pages/pages/ContentForm'),
      meta: {
        permissionsCodes: ['ar/content-pages/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Content Page',
      component: () => import(/* webpackChunkName: "content-pages" */  '@/pages/pages/ContentForm'),
      meta: {
        permissionsCodes: ['ar/content-pages/update', 'restricted_own/ar/content-pages/update'],
      }
    }
  ]
}

export default contentRoutes;
