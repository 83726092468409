import DashboardLayout from "@/pages/layouts/DashboardLayout";

let emailTemplateRoutes = {
  path: '/email-templates',
  component: DashboardLayout,
  redirect: '/email-templates/list',
  children: [
    {
      path: 'list',
      name: 'Email template List',
      component:()=>import(/* webpackChunkName: "email-templates" */ '@/pages/general/emailTemplates/EmailTemplateList'),
      meta: {
        permissionsCodes: ['email-templates/index'],
      }
    },
    {
      path: 'create',
      name: 'Add email template',
      component:()=>import(/* webpackChunkName: "email-templates" */ '@/pages/general/emailTemplates/EmailTemplateForm'),
      meta: {
        permissionsCodes: ['email-templates/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit email template',
      component:()=>import(/* webpackChunkName: "email-templates" */ '@/pages/general/emailTemplates/EmailTemplateForm'),
      meta: {
        permissionsCodes: ['email-templates/update'],
      }
    },
  ]
}

export default emailTemplateRoutes;
