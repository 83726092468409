import DashboardLayout from "@/pages/layouts/DashboardLayout";

let volunteersRoutes = {
  path: '/volunteers',
  component: DashboardLayout,
  redirect: '/volunteers/list',
  children: [
    {
      path: 'List/ar',
      name: 'Volunteers List',
      component: () => import(/* webpackChunkName: "volunteers" */  '@/pages/volunteers/VolunteersList'),
      meta: {
        permissionsCodes: ['ar/volunteers/index'],
      }
    },
    // {
    //   path: 'create/ar',
    //   name: 'Add Volunteer',
    //   component: () => import(/* webpackChunkName: "volunteers" */  '@/pages/volunteers/VolunteersForm'),
    //   meta: {
    //     permissionsCodes: ['ar/volunteers/create'],
    //   }
    // },
    // {
    //   path: 'edit/:id/ar',
    //   name: 'Edit Volunteer',
    //   component: () => import(/* webpackChunkName: "volunteers" */  '@/pages/volunteers/VolunteersForm'),
    //   meta: {
    //     permissionsCodes: ['ar/volunteers/update', 'restricted_own/ar/volunteers/update'],
    //   }
    // }
  ]
}

export default volunteersRoutes;
