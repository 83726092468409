import DashboardLayout from "@/pages/layouts/DashboardLayout";

let songRoutes = {
  path: '/songs',
  component: DashboardLayout,
  redirect: '/songs/list',
  children: [
    {
      path: 'list/ar',
      name: 'Song List',
      component: () => import(/* webpackChunkName: "songs" */  '@/pages/songs/SongList'),
      meta: {
        permissionsCodes: ['ar/songs/index', 'restricted_own/ar/songs/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Song',
      component: () => import(/* webpackChunkName: "songs" */  '@/pages/songs/SongForm'),
      meta: {
        permissionsCodes: ['ar/songs/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Song',
      component: () => import(/* webpackChunkName: "songs" */  '@/pages/songs/SongForm'),
      meta: {
        permissionsCodes: ['ar/songs/update', 'restricted_own/ar/songs/update'],
      }
    }
  ]
}

export default songRoutes;
