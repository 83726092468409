import DashboardLayout from "@/pages/layouts/DashboardLayout";

let emailCampaignRoutes = {
  path: '/email-campaigns',
  component: DashboardLayout,
  redirect: '/email-campaigns/list',
  children: [
    {
      path: 'list',
      name: 'Email campaign List',
      component:()=>import(/* webpackChunkName: "email-campaigns" */ '@/pages/general/emailCampaigns/EmailCampaignList'),
      meta: {
        permissionsCodes: ['email-campaigns/index'],
      }
    },
    {
      path: 'create',
      name: 'Add email campaign',
      component:()=>import(/* webpackChunkName: "email-campaigns" */ '@/pages/general/emailCampaigns/EmailCampaignForm'),
      meta: {
        permissionsCodes: ['email-campaigns/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit email campaign',
      component:()=>import(/* webpackChunkName: "email-campaigns" */ '@/pages/general/emailCampaigns/EmailCampaignForm'),
      meta: {
        permissionsCodes: ['email-campaigns/update'],
      }
    },
  ]
}

export default emailCampaignRoutes;
