import DashboardLayout from "@/pages/layouts/DashboardLayout";

let conferenceArticleRoutes = {
  path: '/conference-articles',
  component: DashboardLayout,
  redirect: '/conference-articles/list',
  children: [
    {
      path: 'list/:lang',
      name: 'Article List',
      component: () => import(/* webpackChunkName: "articles" */  '@/pages/conferences/conferencesArticles/ConferenceArticleList'),
      meta: {
        permissionsCodes: ['ar/conference-articles/index','restricted_own/ar/conference-articles/index'],
      }
    },
    {
      path: 'create/:lang',
      name: 'Add Article',
      component: () => import(/* webpackChunkName: "articles" */  '@/pages/conferences/conferencesArticles/ConferenceArticleForm'),
      meta: {
        permissionsCodes: ['ar/conference-articles/create'],
      }
    },
    {
      path: 'edit/:id/:lang',
      name: 'Edit Article',
      component: () => import(/* webpackChunkName: "articles" */  '@/pages/conferences/conferencesArticles/ConferenceArticleForm'),
      meta: {
        permissionsCodes: ['ar/conference-articles/update','restricted_own/ar/conference-articles/update'],
      }
    }
  ]
}

export default conferenceArticleRoutes;
