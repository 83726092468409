<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/conference-videos/index', 'ar/conference-articles/index', 'ar/conferences/create',
                       'restricted_own/ar/conference-videos/index', 'restricted_own/ar/conference-articles/index'])"
                      :link="{name: 'Conference', icon: 'nc-icon nc-audio-92'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/conferences/create'])"
                        :link="{name: 'Conference', path: '/conferences/create/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/conference-articles/index','restricted_own/ar/conference-articles/index'])"
                        :link="{name: 'Articles', path: '/conference-articles/list/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/conference-videos/index','restricted_own/ar/conference-videos/index'])"
                        :link="{name: 'Videos', path: '/conference-videos/list/ar'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/candidates/index', 'restricted_own/ar/candidates/index'])" :link="{name: 'Candidates', icon: 'nc-icon nc-badge', path: '/candidates/list/ar'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/election-activities/index', 'restricted_own/ar/election-activities/index'])" :link="{name: 'Election Activities', icon: 'nc-icon nc-layers-3', path: '/election-activities/list/ar'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/election-information/index', 'restricted_own/ar/election-information/index'])" :link="{name: 'Election Information', icon: 'nc-icon nc-paper-2', path: '/election-information/list/ar'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/volunteers/index'])" :link="{name: 'Volunteers', icon: 'nc-icon nc-circle-09', path: '/volunteers/list/ar'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/situations/index', 'restricted_own/ar/situations/index'])" :link="{name: 'Situations', icon: 'nc-icon nc-chat-round', path: '/situations/list/ar'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/leadership-groups/index','ar/leaderships/index',
                       'restricted_own/ar/leadership-groups/index','restricted_own/ar/leaderships/index'])"
                      :link="{name: 'Leaderships', icon: 'nc-icon nc-badge'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/leaderships/index','restricted_own/ar/leaderships/index'])"
                        :link="{name: 'Leaderships', path: '/leaderships/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/leadership-groups/index','restricted_own/ar/leadership-groups/index'])"
                        :link="{name: 'Groups', path: '/leadership-groups/list/ar'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/projects/index','ar/project-principles/index',
                       'restricted_own/ar/projects/index','restricted_own/ar/project-principles/index'])"
                      :link="{name: 'Projects', icon: 'nc-icon nc-app'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/project-principles/index','restricted_own/ar/project-principles/index'])"
                        :link="{name: 'Principles', path: '/project-principles/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/projects/index','restricted_own/ar/projects/index'])"
                        :link="{name: 'Projects', path: '/projects/list/ar'}"></sidebar-item>

        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/divisions/index', 'restricted_own/ar/divisions/index'])" :link="{name: 'Divisions', icon: 'nc-icon nc-puzzle-10', path: '/divisions/list/ar'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/video-categories/index','ar/videos/index',
                       'restricted_own/ar/video-categories/index','restricted_own/ar/videos/index'])"
                      :link="{name: 'Videos', icon: 'nc-icon nc-button-play'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/videos/index','restricted_own/ar/videos/index'])"
                        :link="{name: 'Videos', path: '/videos/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/video-categories/index','restricted_own/ar/video-categories/index'])"
                        :link="{name: 'Categories', path: '/video-categories/list/ar'}"></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/gallery-categories/index','ar/gallery/index',
                       'restricted_own/ar/gallery-categories/index','restricted_own/ar/gallery/index'])"
                      :link="{name: 'Gallery', icon: 'nc-icon nc-camera-20'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/gallery/index','restricted_own/ar/gallery/index'])"
                        :link="{name: 'Gallery', path: '/gallery/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/gallery-categories/index','restricted_own/ar/gallery-categories/index'])"
                        :link="{name: 'Categories', path: '/gallery-categories/list/ar'}"></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/songs/index', 'restricted_own/ar/songs/index'])" :link="{name: 'Songs', icon: 'nc-icon nc-note-03', path: '/songs/list/ar'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/networks/index', 'restricted_own/ar/networks/index'])" :link="{name: 'Networks', icon: 'nc-icon nc-mobile', path: '/networks/list/ar'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])" :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])" :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>

        <!--$store.getters['auth/haveOneOfPermissions'](['ar/notifications'])-->

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/pages/index', 'restricted_own/ar/pages/index',
                                             'ar/content-pages/create',
                                             'ar/redirection-pages/create',
                                             'ar/branches/index', '/restricted_own/ar/branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/pages/index','restricted_own/ar/pages/index'])"
                        :link="{name: 'Existing Pages', path: '/pages/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/branches/index','restricted_own/ar/branches/index'])"
                        :link="{name: 'Branches', path: '/branches/list/ar'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/panel-groups/index','ar/panels/index',
                       'restricted_own/ar/panel-groups/index','restricted_own/ar/panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/panels/index','restricted_own/ar/panels/index'])"
                        :link="{name: 'Panels', path: '/panels/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/panel-groups/index','restricted_own/ar/panel-groups/index'])"
                        :link="{name: 'Groups', path: '/panel-groups/list/ar'}"></sidebar-item>

        </sidebar-item>



        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
        (['ar/settings/index', 'restricted_own/ar/settings/index',
        'ar/mediaInfo/index', 'restricted_own/ar/mediaInfo/index'])"
            :link="{name: 'Settings', icon: 'nc-icon nc-circle-09'}">

            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/mediaInfo/index', 'restricted_own/ar/mediaInfo/index'])"
                          :link="{name: 'Media Info', path: '/mediaInfo/list'}">
            </sidebar-item>
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/settings/index', 'restricted_own/ar/settings/index'])"
                          :link="{name: 'Settings', path: '/settings/list/ar'}">
            </sidebar-item>


        </sidebar-item>


        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

        </sidebar-item>





      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
